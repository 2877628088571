import styled from "styled-components";
import Filters from "./filters";

export default styled(Filters)`
  .ais-Panel-header {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px black solid;
  }
  
  display : flex;
  flex-wrap: wrap;
  column-gap: 10px;
  justify-content: space-between;
`