import {InstantSearch, Menu, MenuSelect, NumericMenu, Panel, RefinementList} from "react-instantsearch-dom";
import {default as React} from "react";

const categories = {
	"093f65e0-80a2-95f8-076b-1c5722a46aa2":"GENERAL",
	"98dce83d-a57b-0395-e163-467c9dae521b":"New_categories",
	"42a0e188-f503-3bc6-5bf8-d78622277c4e":"Salé",
	"2a79ea27-c279-e471-f4d1-80b08d62b00a":"Apéros",
	"1c9ac015-9c94-d8d0-cbed-c973445af2da":"Bouchées",
	"6c4b761a-28b7-34fe-9383-1e3fb400ce87":"Croquettes",
	"757b505c-fd34-c64c-85ca-5b5690ee5293":"Croquettes de fromage",
	"3644a684-f98e-a8fe-223c-713b77189a77":"Croquettes de légumes",
	"854d6fae-5ee4-2911-677c-739ee1734486":"Croquettes de viande",
	"e2c0be24-560d-78c5-e599-c2a9c9d0bbd2":"Autres croquettes",
	"06409663-226a-f2f3-1144-85aa4e0a23b4":"Feuilleté",
	"140f6969-d521-3fd0-ece0-3148e62e461e":"Pièce montée salée",
	"ec895663-7a99-787b-d197-eacd77acce5e":"Fromages",
	"a97da629-b098-b75c-294d-ffdc3e463904":"Camembert",
	"6974ce5a-c660-610b-44d9-b9fed0ff9548":"Fondue",
	"f0935e4c-d592-0aa6-c7c9-96a5ee53a70f":"Mont-d'or",
	"c9e1074f-5b3f-9fc8-ea15-d152add07294":"Raclette",
	"65b9eea6-e1cc-6bb9-f0cd-2a47751a186f":"Tartiflette",
	"a8baa565-54f9-6369-ab93-e4f3bb068c22":"Gâteaux salés",
	"903ce922-5fca-3e98-8c2a-f215d4e544d3":"Bûche salée",
	"0a09c884-4ba8-f093-6c20-bd791130d6b6":"Cake salé",
	"2b24d495-052a-8ce6-6358-eb576b8912c8":"Couronne salée",
	"a5e00132-373a-7031-000f-d987a3c9f87b":"Crêpe salée",
	"8d5e957f-2978-9348-7bd9-8fa830fa6413":"Gaufre salée",
	"47d1e990-583c-9c67-424d-369f3414728e":"Terrine",
	"3988c7f8-8ebc-b58c-6ce9-32b957b6f332":"Gratins",
	"013d4071-66ec-4fa5-6eb1-e1f8cbe183b9":"Gratin",
	"85d8ce59-0ad8-981c-a2c8-286f79f59954":"Gratin dauphinois",
	"e00da03b-685a-0dd1-8fb6-a08af0923de0":"Hachis Parmentier",
	"1385974e-d590-4a43-8616-ff7bdb3f7439":"Moussaka",
	"0f28b5d4-9b30-20af-eecd-95b4009adf4c":"Welsh",
	"4c56ff4c-e4aa-f957-3aa5-dff913df997a":"Légumes",
	"a0a080f4-2e6f-13b3-a2df-133f073095dd":"Purée",
	"202cb962-ac59-075b-964b-07152d234b70":"Salade",
	"c8ffe9a5-87b1-26f1-52ed-3d89a146b445":"Soupe",
	"3def184a-d8f4-755f-f269-862ea77393dd":"Tomates mozzarella",
	"f4b9ec30-ad9f-68f8-9b29-639786cb62ef":"Pâtes et Riz",
	"812b4ba2-87f5-ee0b-c9d4-3bbf5bbe87fb":"Bolognaise",
	"26657d5f-f902-0d2a-befe-558796b99584":"Carbonara",
	"ac627ab1-ccbd-b62e-c96e-702f07f6425b":"Gratin de pâtes",
	"ed3d2c21-991e-3bef-5e06-9713af9fa6ca":"Lasagnes",
	"f899139d-f5e1-0593-9643-1415e770c6dd":"Risotto",
	"38b3eff8-baf5-6627-478e-c76a704e9b52":"Sushi",
	"a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b":"Viandes et œufs",
	"2723d092-b638-85e0-d7c2-60cc007e8b9d":"Agneau",
	"5f93f983-524d-ef3d-ca46-4469d2cf9f3e":"Boulettes",
	"698d51a1-9d8a-121c-e581-499d7b701668":"Brochettes",
	"7f6ffaa6-bb0b-4080-17b6-2254211691b5":"Cordon bleu",
	"73278a4a-8696-0eeb-576a-8fd4c9ec6997":"Farcis",
	"5fd0b37c-d7db-bb00-f97b-a6ce92bf5add":"Kebab",
	"2b44928a-e11f-b938-4c4c-f38708677c48":"Œufs",
	"c45147de-e729-311e-f5b5-c3003946c48f":"Omelette",
	"eb160de1-de89-d905-8fcb-0b968dbbbd68":"Steak",
	"5ef05993-8ba7-99aa-a845-e1c2e8a762bd":"Volaille",
	"f2217062-e9a3-97a1-dca4-29e7d70bc6ca":"Sandwichs",
	"7ef605fc-8dba-5425-d696-5fbd4c8fbe1f":"Burger",
	"a8f15eda-80c5-0adb-0e71-943adc8015cf":"Croque-monsieur",
	"37a749d8-08e4-6495-a8da-1e5352d03cae":"Hot dog",
	"b3e3e393-c77e-35a4-a3f3-cbd1e429b5dc":"Sandwich",
	"1d7f7abc-18fc-b439-7506-5399b0d1e48e":"Tacos",
	"069059b7-ef84-0f0c-74a8-14ec9237b6ec":"Tartes, Quiches et Pizza",
	"ec5decca-5ed3-d6b8-079e-2e7e7bacc9f2":"Pizza",
	"76dc611d-6eba-afc6-6cc0-879c71b5db5c":"Quiche",
	"d1f491a4-04d6-8548-8094-3e5c3cd9ca25":"Tarte salée",
	"eae27d77-ca20-db30-9e05-6e3d2dcd7d69":"Tarte saumon",
	"1afa34a7-f984-eeab-dbb0-a7d494132ee5":"Tartine",
	"b73ce398-c39f-506a-f761-d2277d853a92":"Sucré",
	"31fefc0e-570c-b386-0f2a-6d4b38c6490d":"Autres plats sucrés",
	"9dcb88e0-1376-4959-0b75-5372b040afad":"Crêpe",
	"a2557a7b-2e94-197f-f767-970b67041697":"Fondue sucrée",
	"cfecdb27-6f63-4854-f3ef-915e2e980c31":"Glace",
	"0aa1883c-6411-f787-3cb8-3dacb17b0afc":"Petit-déjeuner",
	"045117b0-e0a1-1a24-2b97-65e79cbf113f":"Chocolat",
	"fc221309-7460-13ac-5545-71fbd180e1c8":"Dessert au chocolat",
	"4c5bde74-a8f1-1065-6874-902f07378009":"Fondant au chocolat",
	"cedebb6e-872f-539b-ef8c-3f919874e9d7":"Desserts pâtissiers",
	"6cdd60ea-0045-eb7a-6ec4-4c54d29ed402":"Pâtisserie",
	"eecca5b6-365d-9607-ee5a-9d336962c534":"Tarte sucrée",
	"9872ed9f-c22f-c182-d371-c3e9ed316094":"Tiramisu",
	"36366388-1777-2e42-b59d-74cff571fbb3":"Fruits",
	"149e9677-a598-9fd3-42ae-44213df68868":"Dessert à la banane",
	"a4a042cf-4fd6-bfb4-7701-cbc8a1653ada":"Dessert à la fraise",
	"1ff8a7b5-dc7a-7d1f-0ed6-5aaa29c04b1e":"Dessert à la poire",
	"f7e6c855-04ce-6e82-442c-770f7c8606f0":"Dessert à la pomme",
	"bf822969-6f7a-3bb4-700c-fddef19fa23f":"Dessert fruité",
	"bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb":"Gâteaux",
	"82aa4b0a-f34c-2313-a562-076992e50aa3":"Brioche",
	"0777d5c1-7d40-66b8-2ab8-6dff8a46af6f":"Bûche",
	"fa7cdfad-1a5a-af83-70eb-eda47a1ff1c3":"Cheesecake",
	"9766527f-2b5d-3e95-d4a7-33fcfb77bd7e":"Gros gâteau",
	"006f52e9-102a-8d3b-e2fe-5614f42ba989":"Panettone",
	"82161242-827b-703e-6acf-9c726942a1e4":"Petits gâteaux",
	"38af8613-4b65-d0f1-0fe3-3d30dd76442e":"Biscuits",
	"96da2f59-0cd7-246b-bde0-051047b0d6f7":"Churros",
	"8f53295a-7387-8494-e9bc-8dd6c3c7104f":"Cookies",
	"7e7757b1-e12a-bcb7-36ab-9a754ffb617a":"Madeleines",
	"5878a7ab-84fb-4340-2106-c575658472fa":"Muffin",
	"bd686fd6-40be-98ef-aae0-091fa301e613":"Astuce",
	"274ad478-6c3a-bca6-9fa0-97b85867d9a4":"Astuces",
	"a597e505-02f5-ff68-e3e2-5b9114205d4a":"Astuces cocktails",
	"0336dcba-b05b-9d5a-d24f-4333c7658a0e":"Astuces salées",
	"084b6fbb-1072-9ed4-da8c-3d3f5a3ae7c9":"Astuces sucrées",
	"58a2fc6e-d39f-d083-f55d-4182bf88826d":"Cocktail",
	"07e1cd7d-ca89-a167-8042-477183b7ac3f":"Event",
	"7f39f831-7fbd-b198-8ef4-c628eba02591":"Noël",
	"a3f390d8-8e4c-41f2-747b-fa2f1b5f87db":"Saint-Valentin",
	"54229abf-cfa5-649e-7003-b83dd4755294":"Pâques",
	"072b030b-a126-b2f4-b237-4f342be9ed44":"Halloween",
	"0e65972d-ce68-dad4-d52d-063967f0a705":"Compil",
	"84d9ee44-e457-ddef-7f2c-4f25dc8fa865":"PERDU",
	"da4fb5c6-e93e-74d3-df85-27599fa62642":"Collections",
	"735b90b4-5681-25ed-6c3f-678819b6e058":"Hachis parmentier",
	"fc490ca4-5c00-b124-9bbe-3554a4fdf6fb":"Fondant au chocolat",
	"03afdbd6-6e79-29b1-25f8-597834fa83a4":"Pizza",
	"ea5d2f1c-4608-232e-07d3-aa3d998e5135":"Quiche",
	"14bfa6bb-1487-5e45-bba0-28a21ed38046":"Crêpes",
	"3295c76a-cbf4-caae-d33c-36b1b5fc2cb1":"Cordon bleu"
}

const Filters = ({className}) => {
	return (
		<div className={className}>
			
			<Panel header="Verticales">
				<MenuSelect
					attribute="vertical"
					translations={{
						seeAllOption: 'Voir tout',
					}}
				/>
			</Panel>
			
			<Panel header="Prep time">
				<NumericMenu
					attribute="duration_minutes"
					items={[
						{ label: '- de 15 minutes', end: 15 },
						{ label: '- de 30 minutes', end: 30 },
						{ label: '- de 60 minutes', end: 60 },
					]}
					translations={{
						all: 'Toute durée',
					}}
				/>
			</Panel>
			
			<Panel header="Nombre de personnes">
				<NumericMenu
					attribute="number_of_people"
					items={[
						{ label: 'Solo', end: 1 },
						{ label: 'À deux', start : 2, end: 2 },
						{ label: 'En famille', start : 3, end: 7 },
						{ label: 'Grande tablée', start: 8 },
					]}
					translations={{
						all: 'Toutes les recettes',
					}}
				/>
			</Panel>
			
			<Panel header="Ustensiles">
				<RefinementList
					attribute="kitchenware"
					searchable
					operator="and"
					translations={{
						all: 'Toutes les recettes',
					}}
				/>
			</Panel>
			
			<Panel header="Catégories">
				<Menu
					attribute="categories"
					operator="and"
					transformItems={items =>
						items.map(item => ({
							...item,
							label: categories[item.label],
						}))
					}
				/>
			</Panel>
			
		</div>
	)
}

export default Filters