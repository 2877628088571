import * as React from 'react'
import Layout from '../components/layout'
import Search from "../components/search";
const searchIndices = [{ name: `test-toolpad-recipes`, title: `kinto-chefclub-v2-recipes` }]

const BlogPage = () => {
	return (
		<Layout pageTitle="Les recettes">
			<Search indices={searchIndices} />
		</Layout>
	)
}

export default BlogPage