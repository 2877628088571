import algoliasearch from "algoliasearch/lite"
import {createRef, default as React, useState, useMemo, Component} from "react"
import {InstantSearch, MenuSelect, Pagination, Panel, RefinementList, SortBy} from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import StyledFilters from "./styled-filters"
import StyledSearchBox from "./styled-search-box"
import StyledSearchResult from "./styled-search-result"
import StyledSearchRoot from "./styled-search-root"
import * as PropTypes from "prop-types";

const theme = {
	foreground: "#050505",
	background: "white",
	faded: "#888",
}

class Configure extends Component {
	render() {
		return null;
	}
}

Configure.propTypes = {filters: PropTypes.string};

export default function Search({ indices }) {
	const rootRef = createRef()
	const [query, setQuery] = useState()
	const [hasFocus, setFocus] = useState(false)
	const searchClient = useMemo(
		() =>
			algoliasearch(
				"JKWQZTTE4U",
				"e60f2bef9e1bc41e82de0d3a8fb5d74d"
			),
		[]
	)

	return (
		<ThemeProvider theme={theme}>
			<StyledSearchRoot ref={rootRef}>
				
				<InstantSearch
					searchClient={searchClient}
					indexName={indices[0].name}
					onSearchStateChange={(searchState) => {
						console.log("searchState", searchState)
						var {query} = searchState
						setQuery(query)
					}}
				>
					<StyledFilters />
					
					<StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
					<StyledSearchResult
						show={query && query.length > 0 && hasFocus}
						indices={indices}
					/>
					
					<Pagination
						showFirst={false}
						showLast={false}
						padding={2}
					/>
				</InstantSearch>
				
			</StyledSearchRoot>
		</ThemeProvider>
	)
}